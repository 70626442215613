import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { NotificacaoRequestBody, Notificacao } from "./notificacoes.model";

@Injectable({
  providedIn: "root",
})
export class NotificacoesService {
  private readonly http = inject(HttpClient);

  private readonly notificacoesUrl = environment.apiUrl + "notificacao";

  postNotificacao(notificacao: NotificacaoRequestBody): Observable<any> {
    return this.http.post(this.notificacoesUrl, notificacao);
  }

  getNotificacoesUsuario(): Observable<Notificacao[]> {
    return this.http.get<Notificacao[]>(this.notificacoesUrl);
  }

  deleteNotificacao(id: number): Observable<any> {
    return this.http.delete(`${this.notificacoesUrl}/${id}`);
  }

  toggleNotificacaoLidaState(id: number): Observable<any> {
    return this.http.get(
      `${this.notificacoesUrl}/toggleLida?idUsuarioNotificacao=${id}`,
    );
  }

  updateAllNotificacoesLidasState(
    ids: number[],
    lida: boolean,
  ): Observable<any> {
    return this.http.post(
      `${this.notificacoesUrl}/toggleLida/lista?lida=${lida}`,
      ids,
    );
  }
}
