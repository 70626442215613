import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
  withComputed,
} from "@ngrx/signals";
import {
  initialState,
  Notificacao,
  NotificacaoViewType,
  NotificaoEscopo,
} from "./notificacoes.model";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import {
  OrgaoAdmin,
  OrgaosAdminService,
} from "src/app/core/services/orgaos-admin.service";
import { computed, inject } from "@angular/core";
import {
  debounceTime,
  filter,
  lastValueFrom,
  map,
  pipe,
  switchMap,
  tap,
} from "rxjs";
import { tapResponse } from "@ngrx/operators";
import { NzMessageService } from "ng-zorro-antd/message";
import { UFs } from "src/app/core/constants";
import { NzCheckBoxOptionInterface } from "ng-zorro-antd/checkbox";
import { UsuariosService } from "src/app/usuarios/usuarios/data-access/usuarios.service";
import { OrderDirection } from "src/app/usuarios/usuarios/data-access/usuarios.model";
import { NotificacoesService } from "./notificacoes.service";

export const NotificacoesStore = signalStore(
  withState(initialState),
  withComputed((state) => ({
    computedOrgaosList: computed(() => {
      const uniqueOrgaos = new Set([
        ...state.tempOrgaos().map((u) => JSON.stringify(u)),
        ...state.selectedOrgaos().map((u) => JSON.stringify(u)),
      ]);
      return Array.from(uniqueOrgaos).map((u) => JSON.parse(u));
    }),
    computedUsuariosList: computed(() => {
      const uniqueUsuarios = new Set([
        ...state.tempUsuarios().map((u) => JSON.stringify(u)),
        ...state.selectedUsuarios().map((u) => JSON.stringify(u)),
      ]);
      return Array.from(uniqueUsuarios).map((u) => JSON.parse(u));
    }),
    filteredNotificacoes: computed(() => {
      const notificacoes = state.notificacoes();
      const viewType = state.notificacoesViewType();

      let filteredNotificacoes: Notificacao[];
      switch (viewType) {
        case "todas":
          filteredNotificacoes = notificacoes;
          break;
        case "lidas":
          filteredNotificacoes = notificacoes.filter((n) => !!n.dataLeitura);
          break;
        case "naoLidas":
          filteredNotificacoes = notificacoes.filter((n) => !n.dataLeitura);
          break;
        default:
          filteredNotificacoes = notificacoes;
      }

      // Ordena as notificações em ordem decrescente pela dataEntrega
      const sortedNotificacoes = filteredNotificacoes.sort((a, b) => {
        const dateA = new Date(a.dataEntrega).getTime();
        const dateB = new Date(b.dataEntrega).getTime();
        return dateB - dateA;
      });

      return sortedNotificacoes.slice(
        (state.pageIndex() - 1) * state.pageSize(),
        state.pageIndex() * state.pageSize(),
      );
    }),
    hasUnreadNotifications: computed(() =>
      state.notificacoes().some((n) => !n.dataLeitura),
    ),
    isValidUfs: computed(
      () => state.ufs().filter((uf) => uf.checked).length > 0,
    ),
    requestParams: computed(() => ({
      titulo: state.titulo(),
      mensagem: state.mensagem(),
      escopo: state.escopo(),
      itens:
        state.escopo() === "ORGAO_ID"
          ? state.selectedOrgaos().map((orgao) => orgao.id.toString())
          : state.escopo() === "UF"
            ? state
                .ufs()
                .filter((uf) => uf.checked)
                .map((uf) => uf.value)
            : state.escopo() === "CPF_DO_USUARIO"
              ? state.selectedUsuarios().map((usuario) => usuario.cpf)
              : [],
    })),
  })),
  withMethods(
    (
      store,
      orgaosAdminService = inject(OrgaosAdminService),
      usuariosService = inject(UsuariosService),
      notificacoesService = inject(NotificacoesService),
      messageService = inject(NzMessageService),
    ) => {
      const methods = {
        async enviarNotificacao() {
          patchState(store, { loading: true });
          try {
            const notificacao = store.requestParams();
            if (!notificacao) {
              messageService.error("Nenhuma notificação para enviar");
              return;
            }
            await lastValueFrom(
              notificacoesService.postNotificacao(notificacao),
            );

            await methods.loadNotificacoesUsuario();

            messageService.success("Notificação enviada com sucesso");
            methods.resetForm();
          } catch (error) {
            messageService.error("Houve um erro ao enviar a notificação");
          } finally {
            patchState(store, { loading: false });
          }
        },
        async apagarNotificacao(id: number) {
          patchState(store, { loading: true });

          try {
            await lastValueFrom(notificacoesService.deleteNotificacao(id));
          } catch (error) {
            messageService.error("Erro ao excluir a notificação");
            return;
          }

          try {
            await this.loadNotificacoesUsuario();
          } catch (error) {
            messageService.error("Erro ao atualizar a lista de notificações");
          } finally {
            patchState(store, { loading: false });
          }
        },

        async loadNotificacoesUsuario() {
          const notificacoes = await lastValueFrom(
            notificacoesService.getNotificacoesUsuario(),
          );

          patchState(store, { notificacoes });
        },
        async toggleNotificacaoLida(id: number) {
          patchState(store, { loading: true });
          try {
            await lastValueFrom(
              notificacoesService.toggleNotificacaoLidaState(id),
            );
            await this.loadNotificacoesUsuario();
          } catch (error) {
            messageService.error("Houve um erro ao atualizar a notificação");
          } finally {
            patchState(store, { loading: false });
          }
        },
        async updateAllNotificacoesLidasState(lida: boolean) {
          patchState(store, { loading: true });
          try {
            await lastValueFrom(
              notificacoesService.updateAllNotificacoesLidasState(
                store.notificacoes().map((n) => n.id),
                lida,
              ),
            );
            await this.loadNotificacoesUsuario();
          } catch (error) {
            messageService.error("Houve um erro ao atualizar a notificação");
          } finally {
            patchState(store, { loading: false });
          }
        },
        loadOrgaosByTermo: rxMethod<string>(
          pipe(
            debounceTime(300),
            filter((termo) => termo.length > 2),
            tap(() => patchState(store, { loading: true })),
            switchMap((termo) =>
              orgaosAdminService.getOrgaosByTermo(termo).pipe(
                tapResponse({
                  next: (tempOrgaos) => patchState(store, { tempOrgaos }),
                  error: () => {
                    messageService.error("Houve um erro ao buscar os órgãos");
                    patchState(store, { tempOrgaos: [] });
                  },
                  finalize: () => patchState(store, { loading: false }),
                }),
              ),
            ),
          ),
        ),
        loadUsuariosByTermo: rxMethod<string>(
          pipe(
            debounceTime(300),
            filter((termo) => termo.length > 2),
            tap(() => patchState(store, { loading: true })),
            map((termo) => ({
              nome: termo.toUpperCase(),
              page: 0,
              size: 50,
              orderBy: "nome",
              orderDir: "asc" as OrderDirection,
            })),
            switchMap((params) =>
              usuariosService.findUsuarios(params).pipe(
                tapResponse({
                  next: (response) =>
                    patchState(store, { tempUsuarios: response.content }),
                  error: () => {
                    messageService.error("Houve um erro ao buscar os usuários");
                    patchState(store, { tempUsuarios: [] });
                  },
                  finalize: () => patchState(store, { loading: false }),
                }),
              ),
            ),
          ),
        ),
        onPageIndexChange(index: number) {
          patchState(store, { pageIndex: index });
        },
        resetForm() {
          patchState(store, {
            titulo: "",
            mensagem: "",
            escopo: "TODOS",
            itens: [],
            selectedOrgaos: [],
            selectedUsuarios: [],
            ufs: [],
          });
        },
        setMensagem(mensagem: string) {
          patchState(store, {
            mensagem,
          });
        },
        setNotificacoesViewType(viewType: NotificacaoViewType) {
          patchState(store, { notificacoesViewType: viewType });
        },
        setTitulo(titulo: string) {
          patchState(store, {
            titulo,
          });
        },
        setOrgaoCtrl: (value: string) => {
          patchState(store, { orgaosCtrl: value });
        },
        setSelectedOrgaos: (selectedOrgaos: OrgaoAdmin[]) => {
          patchState(store, { selectedOrgaos });
        },
        setSelectedUfs: (value: NzCheckBoxOptionInterface[]) => {
          patchState(store, {
            ufs: store.ufs().map((uf) => ({
              ...uf,
              checked: value.some((v) => v.value === uf.value),
            })),
          });
        },
        setupUfs: () => {
          patchState(store, {
            ufs: UFs.map((uf) => ({ label: uf, value: uf, checked: false })),
          });
        },
        setUsuariosCtrl: (value: string) => {
          patchState(store, { usuariosCtrl: value });
        },
        setSelectedUsuarios: (value: []) => {
          patchState(store, { selectedUsuarios: value });
        },
        setTargetUsers(escopo: NotificaoEscopo) {
          patchState(store, {
            escopo,
          });
        },
      };
      return methods;
    },
  ),
  withHooks({
    onInit: async (store) => {
      store.loadOrgaosByTermo(store.orgaosCtrl);
      store.loadUsuariosByTermo(store.usuariosCtrl);
      await store.loadNotificacoesUsuario();
      store.setupUfs();
    },
  }),
);
