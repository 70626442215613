import { ChangeDetectorRef } from "@angular/core";
import { NzCheckBoxOptionInterface } from "ng-zorro-antd/checkbox";
import { OrgaoAdmin } from "src/app/core/services/orgaos-admin.service";
import { Usuario } from "src/app/usuarios/usuarios/data-access/usuarios.model";

export type NotificaoEscopo = "TODOS" | "ORGAO_ID" | "UF" | "CPF_DO_USUARIO";

export interface NotificacaoRequestBody {
  titulo: string;
  mensagem: string;
  escopo: NotificaoEscopo;
  itens: string[];
}

export interface Notificacao {
  id: number;
  titulo: string;
  mensagem: string;
  dataEntrega: string;
  dataLeitura: string;
}

export type NotificacaoViewType = "todas" | "lidas" | "naoLidas";

export const initialState = {
  titulo: "",
  mensagem: "",
  escopo: "TODOS" as NotificaoEscopo,
  itens: [] as string[],
  orgaosCtrl: "",
  tempOrgaos: [] as OrgaoAdmin[],
  selectedOrgaos: [] as OrgaoAdmin[],
  loading: false,
  ufs: [] as NzCheckBoxOptionInterface[],
  usuariosCtrl: "",
  tempUsuarios: [] as Usuario[],
  selectedUsuarios: [] as Usuario[],
  notificacao: null as NotificacaoRequestBody | null,
  notificacoes: [] as Notificacao[],
  notificacoesViewType: "todas" as NotificacaoViewType,
  pageIndex: 1,
  pageSize: 5,
};
